<template>
    <LayoutOnlineEditor :slug="slug" input-lang="markdown">
        <template #output>
            <AppMarkdown :content="store.data['online-markdown-editor'].input" />
        </template>
    </LayoutOnlineEditor>
</template>

<script setup lang="ts">
import { useRoute } from "vue-router";
import { useStore } from '%/stores'
import LayoutOnlineEditor from "../LayoutOnlineEditor.vue";
import AppMarkdown from "&/AppMarkdown.vue";
const store = useStore()
const route = useRoute()
const slug = route.path.replace('/', '')
</script>
