<template>
  <div v-if="ready">
    <FeatureSchema :feature="store.feature" />

    <div class="bg-stone-50">
      <main class="max-w-[1800px] mx-auto p-4 pb-12 code-converter">
        <AppBreadcrumbs :breadcrumbs="breadcrumbs" />

        <h1 class="page-title">{{ store.feature.name }}</h1>
        <div class="page-subtitle">{{ store.feature.title }}</div>

        <AdSenseTop />

        <div class="empty:hidden justify-center mt-4 grid grid-cols-2 md:grid-cols-[repeat(auto-fit,_12.5%)] gap-4">
          <router-link
            v-for="relatedFeature in store.feature.related_features"
            :key="relatedFeature.slug"
            class="button button--outline block"
            :to="{ path: relatedFeature.slug }"
          >
            {{ relatedFeature.name }}
          </router-link>
        </div>

        <div class="flex flex-col space-y-4 md:flex-row md:space-x-4 md:space-y-0 mt-4">
          <section class="w-full">
            <slot name="input">
              <div>
                <AppTextInput
                  v-model="store.data[slug].input"
                  :placeholder="store.feature.sample_placeholder"
                  :lang="inputLang"
                  font-class="ace-code-font"
                  :height="inputHeight"
                  @get-sample="store.convertSample(slug)"
                />
              </div>
            </slot>
          </section>

          <section class="min-w-[220px] pt-4 md:pt-12">
            <slot name="action">
              <button
                name="submit"
                class="button button--primary button--large w-full"
                :disabled="store.dataSending"
                @click="store.convert(slug)"
              >
                <span v-if="store.dataSending">
                  <i class="animate-spin fa-solid fa-spinner" />
                </span>
                <span v-else>
                  <i class="fa-solid fa-bolt-lightning"></i>
                </span>
                <span class="ml-1">{{ store.feature.action_text }}</span>
              </button>
            </slot>

            <div class="options empty:hidden mt-4">
              <slot name="options"></slot>
            </div>

            <AdSenseSquare class="mt-8" />
          </section>

          <section class="w-full">
            <slot name="output">
              <div>
                <AppTextInput
                  v-model="store.data[slug].output"
                  :for-output="true"
                  :lang="outputLang"
                  editor-id="ace-output"
                  font-class="ace-code-font"
                  :height="outputHeight"
                  :action-text="store.feature.action_text"
                />
              </div>
            </slot>
          </section>
        </div>

        <AdSenseBottom class="mt-6" />

        <div class="grid grid-cols-12 gap-8 mt-8">
          <div class="col-span-12 md:col-span-8">
            <FeatureInstruction class="" :feature="store.feature" />
          </div>

          <div class="col-span-12 md:col-span-4">
            <AdSenseAside />
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useStore } from '%/stores'
import { useHead } from '@vueuse/head'

import AppTextInput from '&/AppTextInput.vue'
import FeatureSchema from '&/FeatureSchema.vue'
import FeatureInstruction from '&/FeatureInstruction.vue'
import AdSenseTop from '&/adsense/AdSenseTop.vue'
import AdSenseBottom from '&/adsense/AdSenseBottom.vue'
import AdSenseSquare from '&/adsense/AdSenseSquare.vue'
import AdSenseAside from '&/adsense/AdSenseAside.vue'

const props = withDefaults(
  defineProps<{
    slug: string
    inputLang?: string
    outputLang?: string
    inputHeight?: string
    outputHeight?: string
  }>(),
  {
    inputLang: 'text',
    outputLang: 'text',
    inputHeight: '640',
    outputHeight: '640'
  }
)

const store = useStore()
const breadcrumbs = ref([])
const ready = ref(false)

onMounted(() => {
  store
    .fetchFeature(props.slug)
    .then(() => {
      useHead({
        title: store.feature.title,
        meta: [
          {
            name: 'description',
            content: store.feature.description
          }
        ]
      })
      breadcrumbs.value = [
        { path: `/categories/${store.feature.category.slug}`, name: `${store.feature.category.name}` },
        { path: null, name: store.feature.name }
      ]
    })
    .finally(() => (ready.value = true))
})
</script>
