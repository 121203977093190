<template>
  <div class="rounded-md border-2 border-solid border-gray-300 bg-white shadow-xl p-8 bottom-nav">
    <div class="max-w-screen-2xl mx-auto text-center">
      <div class="flex flex-col space-y-4 lg:flex-row lg:space-x-8 lg:space-y-0 justify-center">
        <BuyMeACoffee v-if="!store.dataLoading" />
        <div class="flex space-x-8 justify-center items-center">
          <router-link :to="{ name: 'PageAbout' }"> About </router-link>
          <router-link :to="{ name: 'PageContact' }"> Contact </router-link>
        </div>
      </div>
    </div>
  </div>

  <AllFeatureList />

  <AdSenseFooter />

  <footer class="bg-gray-950">
    <div class="flex flex-col lg:flex-row lg:space-y-0 justify-between">
      <div class="lg:w-1/3"></div>

      <div class="lg:w-1/3 p-8 text-center text-gray-200">
        <div>Copyright &copy; 2023 - {{ new Date().getFullYear() }} ToolPro.dev</div>
        <div class="mt-4 text-gray-200">🇯🇵 From Japan with love ❤️</div>
      </div>

      <div class="lg:w-1/3 p-4 flex justify-end">
        <BuyMeACoffee v-if="!store.dataLoading" id="bmac2" />
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { useStore } from '%/stores'
import AllFeatureList from '&/AllFeatureList.vue'
import BuyMeACoffee from '&/BuyMeACoffee.vue'
import AdSenseFooter from '&/adsense/AdSenseFooter.vue'

const store = useStore()
</script>

<style scoped lang="sass">
.bottom-nav
    a
        color: darkblue
        font-weight: bold
        &:hover
            color: $color-primary-dark
        font-size: 1.3rem
        @include respond(SP)
            font-size: 1rem
</style>
