<template>
    <LayoutTextConverter :slug="slug" input-height="300" output-height="300">
        <template #options>
            <div class="flex space-x-4 justify-center">
                <div class="input-group">
                    <label>Find text</label>
                    <input type="text" v-model="store.data[slug].options.replace_from" placeholder="Find" class="text-field">
                </div>
                <div class="input-group">
                    <label>Replace with</label>
                    <input type="text" v-model="store.data[slug].options.replace_to" placeholder="Replace" class="text-field">
                </div>
            </div>
        </template>
    </LayoutTextConverter>
</template>

<script setup lang="ts">
import { useStore } from '%/stores'
import LayoutTextConverter from "&/LayoutTextConverter.vue"

const store = useStore()
import { useRoute } from "vue-router";
const route = useRoute()
const slug = route.path.replace('/', '')
</script>
