<template>
    <LayoutTextConverter :slug="slug" class="whois-ip-lookup">
        <template #input>
            <div class="w-full max-w-[500px] mx-auto">
                <AppTextField @enter="store.convert(slug)" v-model="store.data[slug].input" label="Domain or IP" :placeholder="store.feature.sample_placeholder">
                    <button class="button button--outline" :disabled="store.dataSending" @click="store.convertSample(slug)">Sample</button>
                </AppTextField>
            </div>
        </template>

        <template #output>
            <div v-if="store.data[slug].output" class="flex flex-col space-y-6 whois-ip-lookup__technical-contacts">
                <AppCard>
                    <template #header>Domain information</template>
                    <template #body>
                        <table class="table table--striped">
                            <tbody>
                            <tr v-if="store.data[slug].output.domain">
                                <td>Domain</td>
                                <td>{{ store.data[slug].output.domain }}</td>
                            </tr>
                            <tr v-if="store.data[slug].output.ip">
                                <td>IP address</td>
                                <td>{{ store.data[slug].output.ip }}</td>
                            </tr>
                            <tr>
                                <td>Available</td>
                                <td>{{ store.data[slug].output.available }}</td>
                            </tr>
                            <tr>
                                <td>Registered</td>
                                <td>{{ store.data[slug].output.registered }}</td>
                            </tr>
                            <tr v-if="store.data[slug].output.created_on">
                                <td>Created on</td>
                                <td>{{ store.data[slug].output.created_on }}</td>
                            </tr>
                            <tr v-if="store.data[slug].output.nameservers">
                                <td>Name Servers</td>
                                <td>
                                    <ul>
                                        <li v-for="ns in store.data[slug].output.nameservers.map(i => i.name)" :key="ns">
                                            {{ ns }}
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </template>
                </AppCard>

                <AppCard v-if="store.data[slug].output.dns_records">
                    <template #header>DNS records</template>
                    <template #body>
                        <table class="table table--striped">
                            <thead>
                            <tr>
                                <th>Domain</th>
                                <th>TTL</th>
                                <th>Class</th>
                                <th>Type</th>
                                <th>Address</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(record, index) in store.data[slug].output.dns_records" :key="index">
                                <td>{{ record.domain }}</td>
                                <td class="text-right">{{ record.ttl }}</td>
                                <td>{{ record.record_class }}</td>
                                <td>{{ record.record_type }}</td>
                                <td class="max-w-[200px] break-all">{{ record.address }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </template>
                </AppCard>

                <AppCard v-if="store.data[slug].output.technical_contacts">
                    <template #header>Technical contact</template>
                    <template #body>
                        <div class="flex flex-col space-y-6">
                            <table class="table table--striped" v-for="contact in store.data[slug].output.technical_contacts" :key="contact.name">
                            <tbody>
                                <tr>
                                    <th>Name</th>
                                    <td>{{ contact.name }}</td>
                                </tr>
                                <tr>
                                    <th>Organization</th>
                                    <td>{{ contact.organization }}</td>
                                </tr>
                                <tr>
                                    <th>Address</th>
                                    <td>{{ contact.address }}</td>
                                </tr>
                                <tr>
                                    <th>City</th>
                                    <td>{{ contact.city }}</td>
                                </tr>
                                <tr>
                                    <th>Zip</th>
                                    <td>{{ contact.zip }}</td>
                                </tr>
                                <tr>
                                    <th>State</th>
                                    <td>{{ contact.state }}</td>
                                </tr>
                                <tr>
                                    <th>Country</th>
                                    <td>{{ contact.country }}</td>
                                </tr>
                                <tr>
                                    <th>Country code</th>
                                    <td>{{ contact.country_code }}</td>
                                </tr>
                                <tr>
                                    <th>Phone</th>
                                    <td>{{ contact.phone }}</td>
                                </tr>
                                <tr>
                                    <th>Fax</th>
                                    <td>{{ contact.fax }}</td>
                                </tr>
                                <tr>
                                    <th>email</th>
                                    <td>{{ contact.email }}</td>
                                </tr>
                                <tr>
                                    <th>URL</th>
                                    <td>{{ contact.url }}</td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                    </template>
                </AppCard>

                <AppCard v-if="store.data[slug].output.raw_data">
                    <template #header>WHOIS raw data</template>
                    <template #body>
                        <div class="flex flex-col space-y-6">
                            <div v-for="(part, index) in store.data[slug].output.raw_data.parts" :key="index">
                                <h4 class="font-bold border-b border-solid border-b-zinc-600 max-w-max mb-4 pb-1">Host: {{ part.host }}</h4>
                                <pre class="whitespace-pre-wrap break-words overflow-x-scroll">{{ part.body }}</pre>
                            </div>
                        </div>
                    </template>
                </AppCard>
            </div>

            <div v-else></div>
        </template>
    </LayoutTextConverter>
</template>

<script setup lang="ts">
import { useStore } from '%/stores'
import AppTextField from "&/AppTextField.vue";
import AppCard from "&/AppCard.vue";
import LayoutTextConverter from "&/LayoutTextConverter.vue"
const store = useStore()

import { useRoute } from "vue-router";
const route = useRoute()
const slug = route.path.replace('/', '')
</script>
