<template>
  <div v-if="ready">
    <FeatureSchema :feature="store.feature" />

    <div class="bg-stone-50">
      <main class="max-w-[1600px] mx-auto p-4 pb-12 programming-editor">
        <AppBreadcrumbs :breadcrumbs="breadcrumbs" />

        <h1 class="page-title">Online {{ store.feature.name }}</h1>
        <div class="page-subtitle">{{ store.feature.title }}</div>

        <AdSenseTop />

        <div class="flex flex-col space-y-4 md:flex-row md:space-x-1 md:space-y-0 mt-4">
          <slot name="input">
            <div class="w-full h-[400px] md:h-[640px] xl:h-[720px]">
              <AppTextInput
                v-model="store.data[slug].input"
                :placeholder="store.feature.sample_placeholder"
                :lang="inputLang"
                font-class="ace-code-font"
                @get-sample="store.getSample(slug)"
              />
            </div>
          </slot>

          <div class="w-full h-[400px] md:h-[640px] xl:h-[720px] bg-white">
            <section
              class="min-h-[50px] p-2 bg-gradient-to-bl from-teal-200 to-green-300 flex justify-between items-center"
            >
              <div class="font-bold">Output {{ store.feature.action_text }}</div>
            </section>
            <div class="p-4 h-[350px] md:h-[590px] xl:h-[670px] overflow-scroll">
              <slot name="output"></slot>
            </div>
          </div>
        </div>

        <AdSenseBottom class="my-8" />

        <div class="grid grid-cols-12 gap-8 mt-8">
          <div class="col-span-12 md:col-span-8">
            <FeatureInstruction class="" :feature="store.feature" />
          </div>

          <div class="col-span-12 md:col-span-4">
            <AdSenseAside />
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useStore } from '%/stores'
import { useHead } from '@vueuse/head'
import AppTextInput from '&/AppTextInput.vue'

import FeatureInstruction from '&/FeatureInstruction.vue'
import AdSenseTop from '&/adsense/AdSenseTop.vue'
import AdSenseBottom from '&/adsense/AdSenseBottom.vue'
import AdSenseAside from '&/adsense/AdSenseAside.vue'
import FeatureSchema from '&/FeatureSchema.vue'

const props = withDefaults(
  defineProps<{
    slug: string
    inputLang?: string
  }>(),
  {
    inputLang: 'text'
  }
)

const store = useStore()
const breadcrumbs = ref([])
const ready = ref(false)

onMounted(() => {
  store
    .fetchFeature(props.slug)
    .then(() => {
      useHead({
        title: store.feature.title,
        meta: [
          {
            name: 'description',
            content: store.feature.description
          }
        ]
      })
      breadcrumbs.value = [
        { path: `/categories/${store.feature.category.slug}`, name: `${store.feature.category.name}` },
        { path: null, name: `Online ${store.feature.name}` }
      ]
    })
    .finally(() => (ready.value = true))
})
</script>
