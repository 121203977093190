<template>
  <LayoutCodeConverter :slug="slug" input-lang="css" output-lang="css">
      <template #options>
          <OptionIndent :slug="slug"/>

          <div class="input-group mt-4">
              <label>
                  <input type="checkbox" v-model="store.data[slug].options.open_brace_end_of_line" @change="store.convert(slug)" >
                  <span>Open brace end of line</span>
              </label>
          </div>

          <div class="input-group mt-4">
              <label>
                  <input type="checkbox" v-model="store.data[slug].options.auto_semicolon" @change="store.convert(slug)">
                  <span>Auto semicolon</span>
              </label>
          </div>
      </template>
  </LayoutCodeConverter>
</template>

<script setup lang="ts">
import { useStore } from '%/stores'
import LayoutCodeConverter from "&/LayoutCodeConverter.vue"
import OptionIndent from "&/OptionIndent.vue";

const store = useStore()
import { useRoute } from "vue-router";
const route = useRoute()
const slug = route.path.replace('/', '')
</script>
