<template>
  <LayoutCodeConverter :slug="slug" input-lang="css" output-lang="css">
      <template #options>
          <div class="input-group">
              <label>Line length</label>
              <input type="number" v-model="store.data[slug].options.length" min="1" class="text-field">
          </div>
      </template>
  </LayoutCodeConverter>
</template>

<script setup lang="ts">
import { useStore } from '%/stores'

import LayoutCodeConverter from "&/LayoutCodeConverter.vue"

const store = useStore()
import { useRoute } from "vue-router";
const route = useRoute()
const slug = route.path.replace('/', '')
</script>
