<template>
    <LayoutTextConverter :slug="slug" output-height="600" :output-lang="store.data[slug].options.language">
        <template #input>
          <div></div>
        </template>

        <template #options>
          <div class="flex flex-col space-y-2 md:flex-row md:space-y-0 md:space-x-8 justify-center items-center">
            <div class="flex space-x-4 items-center">
              <label>Language</label>
              <select v-model="store.data[slug].options.language" class="text-field" @change="store.convert(slug)">
                <option v-for="item in languages"
                        :key="item[1]" :value="item[1]">{{ item[0] }}</option>
              </select>
            </div>
          </div>
        </template>
    </LayoutTextConverter>
</template>

<script setup lang="ts">
import { useStore } from '%/stores'
import LayoutTextConverter from "&/LayoutTextConverter.vue"

const store = useStore()

const languages = [
  ['COBOL', 'cobol'],
  ['Pascal', 'pascal'],
  ['Golang', 'golang'],
  ['VBScript', 'vbscript'],
  ['C Sharp', 'csharp'],
  ['Python', 'python'],
  ['Ruby', 'ruby'],
  ['Java', 'java'],
  ['PHP', 'php'],
  ['HTML', 'html'],
  ['CSS', 'css'],
  ['JavaScript', 'javascript'],
  ['TypeScript', 'typescript'],
  ['JSON', 'json'],
  ['XML', 'xml'],
  ['YAML', 'yaml'],
]

import { useRoute } from "vue-router";
const route = useRoute()
const slug = route.path.replace('/', '')
</script>
