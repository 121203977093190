<template>
  <teleport to="head">
    <component :is="'script'" type="application/ld+json">
      { "@context": "http://schema.org/", "@type": "WebSite", "name": "ToolPro - Online Useful Tools for Developers",
      "description": "Enhance your development workflow with ToolPro's suite of online tools. Simplify code management
      with features like format, minify, beautify, and editors for JSON, XML, YAML, CSS, and JS. Streamline your coding
      tasks for improved readability and performance.", "image": "https://toolpro.dev/img/logo.png", "url":
      "https://toolpro.dev" }
    </component>
  </teleport>

  <div class="max-w-screen-2xl mx-auto">
    <main class="bg-white p-4 pb-12">
      <h1 class="page-title">Online Developer's Toolkit</h1>
      <div class="text-xl">Streamline Your Code with our Minifier, Formatter, Beautifier and more</div>

      <AdSenseTop class="mt-4" />

      <div class="main-menu">
        <section class="bg-gray-100 py-8 mt-4 px-4 lg:px-0">
          <div class="lg:text-lg mx-auto max-w-xl">
            <FeatureSearch />
          </div>
        </section>

        <AdSenseMainMenu class="mt-4" />

        <section v-for="category in store.categories" :key="category.slug" class="mt-8">
          <h2 class="text-2xl font-bold text-primary text-center">
            <router-link :to="{ name: 'CategoryShow', params: { slug: category.slug } }" class="link">
              {{ category.name }}
            </router-link>
          </h2>

          <FeatureList :features="category.features"></FeatureList>
        </section>
      </div>

      <AdSenseBottom class="mt-4" />
    </main>
  </div>
</template>

<script setup lang="ts">
import { useStore } from '%/stores'
import { useHead } from '@vueuse/head'
import FeatureList from '&/FeatureList.vue'
import FeatureSearch from '&/FeatureSearch.vue'
import AdSenseTop from '&/adsense/AdSenseTop.vue'
import AdSenseBottom from '&/adsense/AdSenseBottom.vue'
import AdSenseMainMenu from '&/adsense/AdSenseMainMenu.vue'

const store = useStore()
useHead({
  title: store.systemConfigs.home_title,
  meta: [
    {
      name: 'description',
      content: store.systemConfigs.home_description
    },
    {
      name: 'keywords',
      content: store.systemConfigs.home_keywords
    }
  ]
})
</script>
