<template>
  <header>
    <nav class="navbar navbar-expand-lg navbar-dark bg-gradient-to-b from-primary to-primary-dark shadow-md px-2">
      <div class="container-fluid w-full max-w-[1800px] mx-auto">
        <router-link :to="{ name: 'CategoryIndex' }" class="navbar-brand flex items-center space-x-1 text-2xl">
          <img src="@/images/logo.png" alt="toolpro.dev" class="w-12" />
          <span>ToolPro.dev</span>
        </router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div id="navbarNavDropdown" class="collapse navbar-collapse">
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link :to="{ name: 'CategoryIndex' }" class="nav-link">
                <i class="fa-solid fa-house"></i>
                <span>Home</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'JsonFormatter' }" class="nav-link"> JSON Formatter </router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'XmlFormatter' }" class="nav-link"> XML Formatter </router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'JsBeautifier' }" class="nav-link"> JS Beautifier </router-link>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                CSS Converters
              </a>
              <ul class="dropdown-menu">
                <li v-for="feature in store.getCategoryBySlug('css-converters')?.features" :key="feature.slug">
                  <router-link :to="`/${feature.slug}`" class="dropdown-item">
                    {{ feature.name }}
                  </router-link>
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Online Editors
              </a>
              <ul class="dropdown-menu dropdown-menu--large dropdown-menu-end">
                <li v-for="feature in store.getCategoryBySlug('programming-editors')?.features" :key="feature.slug">
                  <router-link :to="`/${feature.slug}`" class="dropdown-item">
                    {{ feature.name }}
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script setup lang="ts">
import { useStore } from '%/stores'

const store = useStore()
</script>
