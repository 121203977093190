import { createRouter, createWebHistory } from 'vue-router'

import PageAbout from '&/PageAbout.vue'
import PageContact from '&/PageContact.vue'

import CategoryIndex from '&/CategoryIndex.vue'
import CategoryShow from '&/CategoryShow.vue'

import JsonFormatter from '&/features/JsonFormatter.vue'
import JsonMinifier from '&/features/JsonMinifier.vue'
import JsonToXml from '&/features/JsonToXml.vue'
import JsonToYaml from '&/features/JsonToYaml.vue'

import XmlFormatter from '&/features/XmlFormatter.vue'
import XmlMinifier from '&/features/XmlMinifier.vue'
import XmlToJson from '&/features/XmlToJson.vue'
import XmlToYaml from '&/features/XmlToYaml.vue'

import YamlBeautifier from '&/features/YamlBeautifier.vue'
import YamlToJson from '&/features/YamlToJson.vue'
import YamlToXml from '&/features/YamlToXml.vue'

import CssBeautifier from '&/features/CssBeautifier.vue'
import CssMinifier from '&/features/CssMinifier.vue'
import CssToScss from '&/features/CssToScss.vue'
import CssToSass from '&/features/CssToSass.vue'
import CssToLess from '&/features/CssToLess.vue'
import ScssToCss from '&/features/ScssToCss.vue'
import ScssToSass from '&/features/ScssToSass.vue'
import SassToCss from '&/features/SassToCss.vue'
import SassToScss from '&/features/SassToScss.vue'
import LessToCss from '&/features/LessToCss.vue'

import JsBeautifier from '&/features/JsBeautifier.vue'
import JsMinifier from '&/features/JsMinifier.vue'

import Base64Encode from '&/features/Base64Encode.vue'
import Base64Decode from '&/features/Base64Decode.vue'
import Base64ToJson from '&/features/Base64ToJson.vue'
import JsonToBase64 from '&/features/JsonToBase64.vue'
import Base64ToImage from '&/features/Base64ToImage.vue'

import BcryptPasswordGenerator from '&/features/BcryptPasswordGenerator.vue'
import BcryptHashVerifier from '&/features/BcryptHashVerifier.vue'
import SHA512HashGenerator from '&/features/SHA512HashGenerator.vue'

import TextReplacer from '&/features/TextReplacer.vue'
import ReverseString from '&/features/ReverseString.vue'
import CaseConverter from '&/features/CaseConverter.vue'
import RemoveAccents from '&/features/RemoveAccents.vue'

import DecimalToBinary from '&/features/DecimalToBinary.vue'

import MyIpAddress from '&/features/MyIpAddress.vue'
import WhoisIpLookup from '&/features/WhoisIpLookup.vue'
import DnsLookup from '&/features/DnsLookup.vue'

import CmToInches from '&/features/CmToInches.vue'

import RandomUuidGenerator from '&/features/RandomUuidGenerator.vue'
import RandomAddressGenerator from '&/features/RandomAddressGenerator.vue'

import SampleCodeGenerator from '&/features/SampleCodeGenerator.vue'
import CodeRefactor from '&/features/CodeRefactor.vue'

import LayoutProgrammingEditor from '&/LayoutProgrammingEditor.vue'
import MarkdownEditor from '&/features/MarkdownEditor.vue'

const routes = [
  { path: '/about', name: 'PageAbout', component: PageAbout },
  { path: '/contact', name: 'PageContact', component: PageContact },

  { path: '/', name: 'CategoryIndex', component: CategoryIndex },
  { path: '/categories/:slug', name: 'CategoryShow', component: CategoryShow },

  { path: '/json-formatter', name: 'JsonFormatter', component: JsonFormatter },
  { path: '/json-minifier', name: 'JsonMinifier', component: JsonMinifier },
  { path: '/json-to-xml', name: 'JsonToXml', component: JsonToXml },
  { path: '/json-to-yaml', name: 'JsonToYaml', component: JsonToYaml },

  { path: '/xml-formatter', name: 'XmlFormatter', component: XmlFormatter },
  { path: '/xml-minifier', name: 'XmlMinifier', component: XmlMinifier },
  { path: '/xml-to-json', name: 'XmlToJson', component: XmlToJson },
  { path: '/xml-to-yaml', name: 'XmlToYaml', component: XmlToYaml },

  { path: '/yaml-beautifier', name: 'YamlBeautifier', component: YamlBeautifier },
  { path: '/yaml-to-json', name: 'YamlToJson', component: YamlToJson },
  { path: '/yaml-to-xml', name: 'YamlToXml', component: YamlToXml },

  { path: '/css-beautifier', name: 'CssBeautifier', component: CssBeautifier },
  { path: '/css-minifier', name: 'CssMinifier', component: CssMinifier },
  { path: '/css-to-scss', name: 'CssToScss', component: CssToScss },
  { path: '/css-to-sass', name: 'CssToSass', component: CssToSass },
  { path: '/css-to-less', name: 'CssToLess', component: CssToLess },
  { path: '/scss-to-css', name: 'ScssToCss', component: ScssToCss },
  { path: '/scss-to-sass', name: 'ScssToSass', component: ScssToSass },
  { path: '/sass-to-css', name: 'SassToCss', component: SassToCss },
  { path: '/sass-to-scss', name: 'SassToScss', component: SassToScss },
  { path: '/less-to-css', name: 'LessToCss', component: LessToCss },

  { path: '/js-beautifier', name: 'JsBeautifier', component: JsBeautifier },
  { path: '/js-minifier', name: 'JsMinifier', component: JsMinifier },

  { path: '/base64-encode', name: 'Base64Encode', component: Base64Encode },
  { path: '/base64-decode', name: 'Base64Decode', component: Base64Decode },
  { path: '/base64-to-json', name: 'Base64ToJson', component: Base64ToJson },
  { path: '/json-to-base64', name: 'JsonToBase64', component: JsonToBase64 },
  { path: '/base64-to-image', name: 'Base64ToImage', component: Base64ToImage },

  { path: '/bcrypt-password-generator', name: 'BcryptPasswordGenerator', component: BcryptPasswordGenerator },
  { path: '/bcrypt-hash-verifier', name: 'BcryptHashVerifier', component: BcryptHashVerifier },
  { path: '/sha-512-hash-generator', name: 'SHA512HashGenerator', component: SHA512HashGenerator },

  { path: '/text-replacer', name: 'TextReplacer', component: TextReplacer },
  { path: '/reverse-string', name: 'ReverseString', component: ReverseString },
  { path: '/case-converter', name: 'CaseConverter', component: CaseConverter },
  { path: '/remove-accents', name: 'RemoveAccents', component: RemoveAccents },

  { path: '/decimal-to-binary', name: 'DecimalToBinary', component: DecimalToBinary },

  { path: '/my-ip-address', name: 'MyIpAddress', component: MyIpAddress },
  { path: '/whois-ip-lookup', name: 'WhoisIpLookup', component: WhoisIpLookup },
  { path: '/dns-lookup', name: 'DnsLookup', component: DnsLookup },

  { path: '/cm-to-inches', name: 'CmToInches', component: CmToInches },

  { path: '/random-uuid-generator', name: 'RandomUuidGenerator', component: RandomUuidGenerator },
  { path: '/random-address-generator', name: 'RandomAddressGenerator', component: RandomAddressGenerator },

  { path: '/sample-code-generator', name: 'SampleCodeGenerator', component: SampleCodeGenerator },
  { path: '/code-refactor', name: 'CodeRefactor', component: CodeRefactor },

  { path: '/online-markdown-editor', name: MarkdownEditor, component: MarkdownEditor },
  { path: '/:slug', name: 'LayoutProgrammingEditor', component: LayoutProgrammingEditor, props: true }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.afterEach(() => {
  window.scrollTo(0, 0)
})

export default router
