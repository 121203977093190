<template>
    <LayoutTextConverter :slug="slug" class="whois-ip-lookup">
        <template #input>
            <div class="w-full max-w-[500px] mx-auto">
                <AppTextField @enter="store.convert(slug)" v-model="store.data[slug].input" label="Domain or IP" :placeholder="store.feature.sample_placeholder">
                    <button class="button button--outline" :disabled="store.dataSending" @click="store.convertSample(slug)">Sample</button>
                </AppTextField>
            </div>
        </template>

        <template #output>
            <div v-if="store.data[slug].output" class="flex flex-col space-y-6 whois-ip-lookup__technical-contacts">
                <AppCard v-if="store.data[slug].output.dns_records">
                    <template #header>DNS records</template>
                    <template #body>
                        <table class="table table--striped">
                            <thead>
                            <tr>
                                <th>Domain</th>
                                <th>TTL</th>
                                <th>Class</th>
                                <th>Type</th>
                                <th>Address</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(record, index) in store.data[slug].output.dns_records" :key="index">
                                <td>{{ record.domain }}</td>
                                <td class="text-right">{{ record.ttl }}</td>
                                <td>{{ record.record_class }}</td>
                                <td>{{ record.record_type }}</td>
                                <td class="max-w-[200px] break-all">{{ record.address }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </template>
                </AppCard>
            </div>

            <div v-else></div>
        </template>
    </LayoutTextConverter>
</template>

<script setup lang="ts">
import { useStore } from '%/stores'
import AppTextField from "&/AppTextField.vue";
import AppCard from "&/AppCard.vue";
import LayoutTextConverter from "&/LayoutTextConverter.vue"
const store = useStore()

import { useRoute } from "vue-router";
const route = useRoute()
const slug = route.path.replace('/', '')
</script>
