<template>
    <LayoutCodeConverter :slug="slug" :input-lang="inputLang" output-lang="xml">
        <template #options>
            <OptionIndent :slug="slug"/>

            <div class="input-group mt-4 options__case_converter flex flex-col space-y-1 items-start">
                <label>Key converter</label>
                <select v-model="store.data[slug].options.key_converter" class="text-field" @change="store.convert(slug)">
                    <option v-for="item in keyConverterOptions" :key="item[1]" :value="item[1]">{{ item[0] }}</option>
                </select>
            </div>

            <div class="input-group mt-4">
                <label>
                    <input type="checkbox" v-model="store.data[slug].options.pretty" @change="store.convert(slug)">
                    <span>Pretty print</span>
                </label>
            </div>

            <div class="input-group mt-4">
                <label>
                    <input type="checkbox" v-model="store.data[slug].options.compact" @change="store.convert(slug)">
                    <span>Compact</span>
                </label>
            </div>

            <div class="input-group mt-4">
                <label>
                    <input type="checkbox" v-model="store.data[slug].options.declaration" @change="store.convert(slug)">
                    <span>Declaration</span>
                </label>
            </div>
        </template>
    </LayoutCodeConverter>
</template>

<script setup lang="ts">
import LayoutCodeConverter from "&/LayoutCodeConverter.vue"
import OptionIndent from "&/OptionIndent.vue";
import { useRoute } from "vue-router";
import { useStore } from '%/stores'

const store = useStore()
const route = useRoute()
const slug = route.path.replace('/', '')

const inputLang = route.name === 'JsonToXml' ? 'json' : 'yaml'

const keyConverterOptions = [
    ['None', 'none'],
    ['camelCase', 'lower_camelcase'],
    ['PascalCase', 'camelcase'],
    ['UPCASE', 'upcase']
]
</script>
