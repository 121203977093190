<template>
    <LayoutTextConverter :slug="slug" input-height="150" output-height="150">
        <template #options>
            <div class="grid grid-cols-12 gap-6">
                <label v-for="c in cases" :key="c.value" class="col-span-6 md:col-span-3 input-group button button--outline cursor-pointer">
                        <input type="radio" v-model="store.data[slug].options.case" :value="c.value" @change="store.convert(slug)">
                        <span>{{ c.text }}</span>
                </label>
            </div>
        </template>

        <template #submit><div></div></template>
    </LayoutTextConverter>
</template>

<script setup lang="ts">
import { useStore } from '%/stores'
import LayoutTextConverter from "&/LayoutTextConverter.vue"

const store = useStore()
import { useRoute } from "vue-router";
const route = useRoute()
const slug = route.path.replace('/', '')

const cases = [
    { text: 'Upper case', value: 'upcase' },
    { text: 'Lower case', value: 'downcase' },
    { text: 'Title case', value: 'titleize' },
    { text: 'Capitalize', value: 'capitalize' },
    { text: 'Pascal case', value: 'pascal' },
    { text: 'Camel case', value: 'camelize' },
    { text: 'Snake case', value: 'underscore' },
    { text: 'Kebab case', value: 'parameterize' },
    { text: 'Swap case', value: 'swapcase' },
]
</script>
