import { useRoute } from "vue-router";
import { useStore } from '%/stores'
import LayoutCodeConverter from "&/LayoutCodeConverter.vue";
import LayoutTextConverter from "&/LayoutTextConverter.vue";
import LayoutProgrammingEditor from "&/LayoutProgrammingEditor.vue";
import LayoutOnlineEditor from "&/LayoutOnlineEditor.vue";

export function useFeatureSetup() {
    const route = useRoute()
    const store = useStore()
    const slug = route.path.replace('/', '')

    return { route, store, slug, LayoutCodeConverter, LayoutTextConverter, LayoutProgrammingEditor, LayoutOnlineEditor }
}
