<template>
    <LayoutTextConverter :slug="slug" class="my-ip-address">
        <template #input>
            <div class="w-full max-w-[500px] mx-auto">
                <AppTextField @enter="store.convert(slug)" v-model="store.data[slug].input" label="IP address" :placeholder="store.feature.sample_placeholder" @focus="selectInputValue($event)">
                    <button class="button button--outline w-full" :disabled="store.dataSending" @click="whatIsMyIpAddress">What is my IP address?</button>
                </AppTextField>
            </div>
        </template>

        <template #output>
          <div v-if="store.dataSending">
            <AppSpinner message="Checking your IP address..."/>
          </div>
            <div v-else class="mt-8 grid grid-cols-1 md:grid-cols-2 gap-6">
                <AppCard class="">
                    <template #header>IP information</template>
                    <template #body>
                        <table class="table table--striped w-full">
                            <tbody>
                              <tr>
                                <th>IP</th>
                                <td>{{ store.data[slug].output.ip }}</td>
                              </tr>
                            <tr>
                              <th>Country</th>
                              <td>{{ store.data[slug].output.country_long }}</td>
                            </tr>
                              <tr>
                                <th>Region</th>
                                <td>{{ store.data[slug].output.region }}</td>
                              </tr>
                              <tr>
                                <th>City</th>
                                <td>{{ store.data[slug].output.city }}</td>
                              </tr>
                              <tr>
                                <th>Timezone</th>
                                <td>{{ store.data[slug].output.timezone }}</td>
                              </tr>
                              <tr>
                                <th>Zipcode</th>
                                <td>{{ store.data[slug].output.zipcode }}</td>
                              </tr>
                            </tbody>
                        </table>
                    </template>
                </AppCard>

                <AppGoogleMap
                  v-if="store.data[slug].output.latitude"
                  class="min-h-[320px]"
                  :lat="Number(store.data[slug].output.latitude)"
                  :lng="Number(store.data[slug].output.longitude)"
                />
            </div>
        </template>
    </LayoutTextConverter>
</template>

<script setup lang="ts">
import { useFeatureSetup } from '&/composable/featureSetup'
import { onMounted} from "vue";
import { selectInputValue } from '%/utils'
import axios from "axios";
import AppTextField from "&/AppTextField.vue";
import AppCard from "&/AppCard.vue";
import AppGoogleMap from "&/AppGoogleMap.vue";
import AppSpinner from "&/AppSpinner.vue";

const { route, store, slug, LayoutTextConverter } = useFeatureSetup()

onMounted(() => {
  whatIsMyIpAddress()
})

function whatIsMyIpAddress() {
  store.dataSending = true
  axios.get('https://api.ipify.org?format=json')
    .then(res => {
      store.data['my-ip-address'].input = res.data.ip
      return store.data[slug].input
    })
    .then(() => { store.convert(slug) })
    .catch(err => {
      console.error('Error fetching IP:', err);
    })
    .finally(() => store.dataSending = false)
}
</script>

<style scoped lang="sass">
.my-ip-address
  table
    tbody
      tr
        td
          color: darkblue
          font-weight: bold
          font-size: 1.5rem
          word-break: break-word
</style>
