<template>
    <LayoutTextConverter :slug="slug" output-height="300">
        <template #input>
            <div class="italic">
                <p>Please note that the addresses generated here are completely random and fake.</p>
                <p>They do not correspond to actual physical locations. These fake addresses are meant to be used for testing, data filling, or other legal purposes.</p>
            </div>
        </template>

        <template #options>
            <div class="flex flex-col space-y-2 md:flex-row md:space-y-0 md:space-x-8 justify-center items-center">
                <div class="flex space-x-4 items-center">
                    <label>Country</label>
                        <select v-model="store.data[slug].options.country" class="text-field" @change="store.convert(slug)">
                            <option v-for="item in countries" :key="item[1]" :value="item[1]">{{ item[0] }}</option>
                        </select>
                </div>
            </div>
        </template>

        <template #output>
            <div class="mx-auto pt-4">
                <table class="table table--striped text-lg md:min-w-[500px] shadow">
                    <tbody>
                        <tr v-if="store.data[slug].output.building_number">
                            <th class="text-left md:w-[200px]">Building number</th>
                            <td>{{ store.data[slug].output.building_number }}</td>
                        </tr>
                        <tr v-if="store.data[slug].output.street_name">
                            <th class="text-left">Street name</th>
                            <td>{{ store.data[slug].output.street_name }}</td>
                        </tr>
                        <tr v-if="store.data[slug].output.street_address">
                            <th class="text-left">Street address</th>
                            <td>{{ store.data[slug].output.street_address }}</td>
                        </tr>
                        <tr v-if="store.data[slug].output.region">
                            <th class="text-left">Region</th>
                            <td>{{ store.data[slug].output.region }}</td>
                        </tr>
                        <tr v-if="store.data[slug].output.state">
                            <th class="text-left">State</th>
                            <td>{{ store.data[slug].output.state }}</td>
                        </tr>
                        <tr v-if="store.data[slug].output.province">
                            <th class="text-left">Province</th>
                            <td>{{ store.data[slug].output.province }}</td>
                        </tr>
                        <tr v-if="store.data[slug].output.city">
                            <th class="text-left">City</th>
                            <td>{{ store.data[slug].output.city }}</td>
                        </tr>
                        <tr v-if="store.data[slug].output.postal_code">
                            <th class="text-left">Postal code</th>
                            <td>{{ store.data[slug].output.postal_code }}</td>
                        </tr>
                        <tr v-if="store.data[slug].output.full_address">
                            <th class="text-left">Full address</th>
                            <td>{{ store.data[slug].output.full_address }}</td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </template>
    </LayoutTextConverter>
</template>

<script setup lang="ts">
import { useStore } from '%/stores'
import LayoutTextConverter from "&/LayoutTextConverter.vue"

const store = useStore()
import { useRoute } from "vue-router";
const route = useRoute()
const slug = route.path.replace('/', '')

const countries = [
    ['United States', 'US'],
    ['Australia', 'AU'],
    ['Brazil', 'BR'],
    ['Canada', 'CA'],
    ['Switzerland', 'CH'],
    ['Germany', 'DE'],
    ['Finland', 'FI'],
    ['France', 'FR'],
    ['Greece', 'GR'],
    ['Indonesia', 'ID'],
    ['India', 'IN'],
    ['Italy', 'IT'],
    ['Japan', 'JA'],
]
</script>
