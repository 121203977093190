<template>
  <LayoutCodeConverter :slug="slug" input-lang="javascript" output-lang="javascript">
      <template #options>
          <OptionIndent :slug="slug"/>

          <div class="input-group mt-4">
              <label>
                  <input type="checkbox" v-model="store.data[slug].options.remove_comments"  @change="store.convert(slug)">
                  <span>Remove comments</span>
              </label>
          </div>

          <div class="input-group mt-4">
              <label>
                  <input type="checkbox" v-model="store.data[slug].options.remove_unreachable_code" @change="store.convert(slug)" >
                  <span>Remove unreachable code</span>
              </label>
          </div>

          <div class="input-group mt-4">
              <label>
                  <input type="checkbox" v-model="store.data[slug].options.remove_debugger"  @change="store.convert(slug)">
                  <span>Remove debugger</span>
              </label>
          </div>
      </template>
  </LayoutCodeConverter>
</template>

<script setup lang="ts">
import { useStore } from '%/stores'

import LayoutCodeConverter from "&/LayoutCodeConverter.vue"
import OptionIndent from "&/OptionIndent.vue";

const store = useStore()
import { useRoute } from "vue-router";
const route = useRoute()
const slug = route.path.replace('/', '')
</script>
